import React, { useEffect } from "react";
import "./HairsIndiaHomepage.css";
import country from "../../assets/images/homepage/3.png";
import banner from "../../assets/images/homepage/home_page_banner.jpeg";
import Header from "../../core/navbar/header/Header";
import owner_bg from "../../assets/images/homepage/owner/bg.png";
import green from "../../assets/images/homepage/enquire/green.png";
import address from "../../assets/images/homepage/enquire/location.png";
import Contact from "../../assets/images/homepage/enquire/phone.png";
import mail from "../../assets/images/homepage/enquire/mail.png";
import web from "../../assets/images/homepage/enquire/web.png";
import chat from "../../assets/images/homepage/enquire/chat.png";
import banner1 from "../../assets/images/homepage/BG.png";
import OrderHere from "../order-here/OrderHere";
import AboutUs from "../about-us/AboutUs";
import natrajar1 from "../../assets/images/homepage/enquire/n.png";
import enq1 from "../../assets/images/homepage/enquire/enquire.png";
import enq2 from "../../assets/images/homepage/enquire/text.png";
import Products from "../products/Products";
import owner_m_bg from "../../assets/images/homepage/owner/bg-m.png";
import Aos from "aos";
import "aos/dist/aos.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import ReviewsPage from "../reviews-page/ReviewsPage";

function HairsIndiaHomepage() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  useEffect(() => {
    const elements = document.querySelectorAll("[data-aos]");
    console.log("Elements with AOS:", elements);
  }, []);

  return (
    <>
      <Header />
      <div id="homepage" className="hairsindia-homepage-container relative">
        <div className="hairsindia-homepage-banner-wrapper relative">
          <img
            src={banner}
            className="hairsindia-homepage-banner-image"
            alt="banner"
          />
          <img
            src={banner1}
            className="hairsindia-homepage-banner"
            alt="banner"

          />
        </div>
        <img
          className="hairsindia-india-image"
          src={country}
          alt="banner_image"
        />
      </div>
      <AboutUs />

      <ScrollAnimation
        animateIn="fadeIn"
        duration={5}
        animatePreScroll={false}
        animateOnce={true}
      >
        <div className="hairsindia-owner-container relative flex items-center">
          <img className="owner-bg" src={owner_bg} alt="background_image" />
          <img className="owner-m-bg" src={owner_m_bg} alt="background_image" />
          <span className="flex flex-col hairsindia-name-content">
            <label className="hairsindia-owner-name">Surenth</label>
            <label className="hairsindia-owner-position">OWNER</label>
          </span>
        </div>
      </ScrollAnimation>

      <Products />

      {/* <OrderHere /> */}
   

      {/* <div className="sections-divider"></div> */}

      <div
        id="enquire-us"
        className="hairsindia-enquire-container relative flex"
      >
        <img
          className="hairsindia-enquire-text-image3 hairsindia-natrajar"
          src={natrajar1}
        />
        <div className="hairsindia-enquire-text-image absolute top-0 left-0 flex">
          <img src={enq2} className="chi-enquire-text-cntr2" />
          <img src={enq1} className="chi-enquire-text-cntr" />
          <img className="hairsindia-enquire-text-image2" src={green} />
        </div>
        <div className="hairsindia-enquire-section">
          <div className="hairsindia-enquire-address flex items-start gap-[10px]">
            <img src={address} className="mt-[8px]" />
            <p
              className="text-[16px] md:text-[25px]"
              style={{ color: "#000", fontWeight: "600" }}
            >
              No.16A, Salim Jublie Square, First Floor, <br />
              F2, Pillaiyar Kovil Street, Gandhi Nagar, Saligramam, Chennai- 600
              093.
            </p>
          </div>
          <div className="hairsindia-enquire-contact flex items-center gap-[10px]  py-[10px]">
            <img src={Contact} />
            <p
              className="text-[19px] md:text-[34px]"
              style={{ fontSize: "34px", color: "#000", fontWeight: "700" }}
            >
              +91 75022 15707
            </p>
          </div>
          <div className="hairsindia-enquire-mail flex items-center gap-[10px]  py-[10px]">
            <img src={mail} />
            <label
              className="text-[16px] md:text-[25px]"
              style={{ color: "#000", fontWeight: "600" }}
            >
              surenth.boss@gmail.com
            </label>
          </div>
          <div className="hairsindia-enquire-web flex items-center gap-[10px] ">
            <div
              className=" flex items-center gap-[10px] py-[10px] pr-[20px]"
              style={{ borderBottom: "2px dashed #3a6108" }}
            >
              <img src={web} />
              <span
                className="text-[16px] md:text-[25px]"
                style={{ color: "#000", fontWeight: "600" }}
              >
                www.chennaihairsindia.com
              </span>
            </div>
            <img className="hairsindia-enquire-chat" src={chat} />
          </div>
        </div>
      </div>
      <div className="sections-divider"></div>
      <ReviewsPage />
      <div className="sections-divider"></div>
    </>
  );
}

export default HairsIndiaHomepage;
