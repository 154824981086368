import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import "./utils/style/base.css";
import HairsIndiaHomepage from "./modules/hairsindia-homepage/HairsIndiaHomepage";
import "./modules/hairsindia-homepage/HairsIndiaHomepage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import ReviewsPage from "./modules/reviews-page/ReviewsPage";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  function NavigateToPractice() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/chennaihairsindia/homepage", { replace: true });
    }, []);
    return null;
  }
  return (
    <Routes>
      <Route path="/" element={<NavigateToPractice />} />
      <Route path="/chennaihairsindia/homepage" element={<HairsIndiaHomepage />} />
      <Route path="/chennaihairsindia/reviews" element={<ReviewsPage/>} />
    </Routes>
  );
}

export default App;
