import React, { useState, useEffect, useRef } from "react";
import "./ReviewsPage.css";
import review_sculp from "../../assets/images/reviews/sculp.png";
// import review_sculp_video from "../../assets/images/reviews/sculp -vido.png";
// import img1 from "../../assets/images/reviews/sculp - photo.png";
import videoFile1 from "../../assets/images/reviews/video/1.mp4";
import videoFile2 from "../../assets/images/reviews/video/2.mp4";
import videoFile3 from "../../assets/images/reviews/video/3.mp4";
import videoFile4 from "../../assets/images/reviews/video/4.mp4";
import videoFile5 from "../../assets/images/reviews/video/5.mp4";
import videoFile6 from "../../assets/images/reviews/video/6.mp4";
import videoFile7 from "../../assets/images/reviews/video/7.mp4";
import videoFile8 from "../../assets/images/reviews/video/8.mp4";
import videoFile9 from "../../assets/images/reviews/video/9.mp4";
import videoFile10 from "../../assets/images/reviews/video/10.mp4";

import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import img01 from "../../assets/images/reviews/photo/photo-01.png";
import img02 from "../../assets/images/reviews/photo/photo-02.png";
import img03 from "../../assets/images/reviews/photo/photo-03.png";
import img04 from "../../assets/images/reviews/photo/photo-04.png";
import img05 from "../../assets/images/reviews/photo/photo-05.png";
import img06 from "../../assets/images/reviews/photo/photo-06.png";
import img07 from "../../assets/images/reviews/photo/photo-07.png";
import img08 from "../../assets/images/reviews/photo/photo-08.png";
import img09 from "../../assets/images/reviews/photo/photo-09.png";
import img10 from "../../assets/images/reviews/photo/photo-10.png";
import cimg01 from "../../assets/images/reviews/chat/c1.png";
import cimg02 from "../../assets/images/reviews/chat/c2.png";
import cimg03 from "../../assets/images/reviews/chat/c3.png";
import cimg04 from "../../assets/images/reviews/chat/c4.png";
import cimg05 from "../../assets/images/reviews/chat/c5.png";
import cimg06 from "../../assets/images/reviews/chat/c6.png";
import cimg07 from "../../assets/images/reviews/chat/c7.png";
import cimg08 from "../../assets/images/reviews/chat/c8.png";
import cimg09 from "../../assets/images/reviews/chat/c9.png";
import cimg10 from "../../assets/images/reviews/chat/c10.png";

import gif_image from "../../assets/images/reviews/logo-gif.gif";
import logo_image from "../../assets/images/reviews/Chennai Hairs India.png";
// import logo_image from "../../assets/images/reviews/logo.png";
import sss from "../../assets/images/homepage/product/img2.png";
import Parallax from "parallax-js";
import WOW from "wowjs";
import main_image from "../../assets/images/reviews/main-image.png";

function ReviewsPage() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const arrowIcon = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25px"
      viewBox="0 -960 860 960"
      width="25px"
      fill="#ffffff"
      className="arrow-icon"
    >
      <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
    </svg>,
  ];

  const playIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25px"
      viewBox="0 -960 960 960"
      width="25px"
      fill="#ffffff"
    >
      <path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" />
    </svg>
  );

  const pauseIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#ffffff"
    >
      <path
        d="M520-200v-560h240v560H520Zm-320 0v-560h240v560H200Zm400-80h80v-400h-80v400Zm-320 0h80v-400h-80v400Zm0-400v400-400Zm320 0v400-400Z"
        stroke="#fff"
      />
    </svg>
  );
  const videos = [
    { src: videoFile1 },
    { src: videoFile2 },
    { src: videoFile3 },
    { src: videoFile4 },
    { src: videoFile5 },
    { src: videoFile6 },
    { src: videoFile7 },
    { src: videoFile8 },
    { src: videoFile9 },
    { src: videoFile10 },
  ];

  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const swiperVideoRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(videos[0]?.src);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);

    return () => {
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
    };
  }, [selectedVideo]);

  const handleVideoClick = (videoSrc, index) => {
    if (videoRef.current) {
      if (selectedVideo !== videoSrc) {
        videoRef.current.pause();
        setIsPlaying(false);
        setSelectedVideo(videoSrc);
        setActiveVideoIndex(index);
      } else {
        if (isPlaying) {
          videoRef.current.pause();
          setIsPlaying(false);
        } else {
          videoRef.current.play();
          setIsPlaying(true);
        }
      }
      // setShowIcon(true);
      // setTimeout(() => setShowIcon(false), 2000);
    }
  };

  const handleVideoPass = (videoSrc, index) => {
    setSelectedVideo(videoSrc);
    setActiveVideoIndex(index);
    if (swiperVideoRef.current) {
      swiperVideoRef.current.swiper.slideTo(index);
    }

    if (activeVideoIndex !== index) {
      setActiveVideoIndex(index);
      setIsPlaying(false);
    }
  };

  // const handleVideoPass = (videoSrc, index) => {
  //   if (activeVideoIndex !== index) {
  //     setActiveVideoIndex(index);
  //     setIsPlaying(true); // Set to true if you want to start playing the new video automatically
  //   } else {
  //     // Toggle play/pause if the same video is clicked
  //     if (isPlaying) {
  //       videoRef.current.pause();
  //       setIsPlaying(false);
  //     } else {
  //       videoRef.current.play();
  //       setIsPlaying(true);
  //     }
  //   }
  // };

  const [navigationVideoButtonHide, setNavigationVIdeoButtonHide] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const handleVideoNextButtonClick = () => {
    if (swiperVideoRef.current && swiperVideoRef.current.swiper) {
      swiperVideoRef.current.swiper.slideNext();
      setNavigationVIdeoButtonHide({
        isEnd: swiperVideoRef?.current?.swiper?.isEnd,
        isBeginning: swiperVideoRef?.current?.swiper?.isBeginning,
      });
    }
  };
  const handleVideoPrevButtonClick = () => {
    if (swiperVideoRef.current && swiperVideoRef.current.swiper) {
      swiperVideoRef.current.swiper.slidePrev();
      setNavigationVIdeoButtonHide({
        isEnd: swiperVideoRef?.current?.swiper?.isEnd,
        isBeginning: swiperVideoRef?.current?.swiper?.isBeginning,
      });
    }
  };

  const photos = [
    { image: img01 },
    { image: img02 },
    { image: img03 },
    { image: img04 },
    { image: img05 },
    { image: img06 },
    { image: img07 },
    { image: img08 },
    { image: img09 },
    { image: img10 },
  ];

  const [selectedImage, setSelectedImage] = useState(photos[0]?.image);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const swiperPhotoRef = useRef(null);

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setActivePhotoIndex(index);
    if (swiperPhotoRef.current) {
      swiperPhotoRef.current.swiper.slideTo(index);
    }
  };

  const [navigationButtonHide, setNavigationButtonHide] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const handlePhotoNextButtonClick = () => {
    if (swiperPhotoRef.current && swiperPhotoRef.current.swiper) {
      swiperPhotoRef.current.swiper.slideNext();
      setNavigationButtonHide({
        isEnd: swiperPhotoRef?.current?.swiper?.isEnd,
        isBeginning: swiperPhotoRef?.current?.swiper?.isBeginning,
      });
    }
  };
  const handlePhotoPrevButtonClick = () => {
    if (swiperPhotoRef.current && swiperPhotoRef.current.swiper) {
      swiperPhotoRef.current.swiper.slidePrev();
      setNavigationButtonHide({
        isEnd: swiperPhotoRef?.current?.swiper?.isEnd,
        isBeginning: swiperPhotoRef?.current?.swiper?.isBeginning,
      });
    }
  };

  const Chat = [
    { image: cimg01 },
    { image: cimg02 },
    { image: cimg03 },
    { image: cimg04 },
    { image: cimg05 },
    { image: cimg06 },
    { image: cimg07 },
    { image: cimg08 },
    { image: cimg09 },
    { image: cimg10 },
  ];

  const [selectedChat, setSelectedChat] = useState(Chat[0]?.image);
  const [activeChatIndex, setActiveChatIndex] = useState(0);
  const swiperChatRef = useRef(null);

  const handleChatClick = (image, index) => {
    setSelectedChat(image);
    setActiveChatIndex(index);
    if (swiperChatRef.current) {
      swiperChatRef.current.swiper.slideTo(index);
    }
  };

  const [navigationChatButtonHide, setNavigationChatButtonHide] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const handleChatNextButtonClick = () => {
    if (swiperChatRef.current && swiperChatRef.current.swiper) {
      swiperChatRef.current.swiper.slideNext();
      setNavigationChatButtonHide({
        isEnd: swiperChatRef?.current?.swiper?.isEnd,
        isBeginning: swiperChatRef?.current?.swiper?.isBeginning,
      });
    }
  };
  const handleChatPrevButtonClick = () => {
    if (swiperChatRef.current && swiperChatRef.current.swiper) {
      swiperChatRef.current.swiper.slidePrev();
      setNavigationChatButtonHide({
        isEnd: swiperChatRef?.current?.swiper?.isEnd,
        isBeginning: swiperChatRef?.current?.swiper?.isBeginning,
      });
    }
  };

  const scrollToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const reviews = [
    { title: "Video Reviews", anchor: "video-reviews" },
    { title: "Photo Reviews", anchor: "photo-reviews" },
    { title: "Chat Reviews", anchor: "chat-reviews" },
  ];

  const parallaxContainerRef = useRef(null);
  const parallaxContainerRef1 = useRef(null);
  const parallaxContainerRef2 = useRef(null);
  const parallaxContainerRef3 = useRef(null);
  const parallaxContainerRef4 = useRef(null);
  const parallaxContainerRef5 = useRef(null);

  useEffect(() => {
    // Initialize Parallax
    const scene = parallaxContainerRef.current;
    const scene1 = parallaxContainerRef1.current;
    const scene2 = parallaxContainerRef2.current;
    const scene3 = parallaxContainerRef3.current;
    const scene4 = parallaxContainerRef4.current;
    const scene5 = parallaxContainerRef5.current;

    if (scene) {
      new Parallax(scene, {
        relativeInput: true,
        hoverOnly: false,
      });
    }
    if (scene1) {
      new Parallax(scene1, {
        relativeInput: true,
        hoverOnly: false,
      });
    }
    if (scene2) {
      new Parallax(scene2, {
        relativeInput: true,
        hoverOnly: false,
      });
    }
    if (scene3) {
      new Parallax(scene3, {
        relativeInput: true,
        hoverOnly: false,
      });
    }
    if (scene4) {
      new Parallax(scene4, {
        relativeInput: true,
        hoverOnly: false,
      });
    }
    if (scene5) {
      new Parallax(scene5, {
        relativeInput: true,
        hoverOnly: false,
      });
    }

    // Cleanup on component unmount
    return () => {
      // if (scene) {
      //   scene.destroy();
      // }
    };
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY;
      
  //     if (parallaxContainerRef.current) {
  //       parallaxContainerRef.current.style.transform = `translateY(${scrollY * 0.06}px)`;
  //     }
  //     if (parallaxContainerRef1.current) {
  //       parallaxContainerRef1.current.style.transform = `translateY(${scrollY * 0.06}px)`;
  //     }
  //     if (parallaxContainerRef2.current) {
  //       parallaxContainerRef2.current.style.transform = `translateY(${scrollY * 0.06}px)`;
  //     }
  //     if (parallaxContainerRef3.current) {
  //       parallaxContainerRef3.current.style.transform = `translateY(${scrollY * 0.25}px)`;
  //     }
  //     if (parallaxContainerRef4.current) {
  //       parallaxContainerRef4.current.style.transform = `translateY(${scrollY * 0.25}px)`;
  //     }
    
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  
  //   // Cleanup on component unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div id="reviews" className="hairsindia-review-container">
      <div className="hairsindia-review-main-page relative pb-[40px] lg:pb-[100px]">
        <img className="hairsindia-gif-image" src={logo_image} alt="image" />
        <img src={review_sculp} className="hairsindia-review-main-sculp" />

        <div className="hairsindia-main-page relative flex justify-center items-center lg:mt-[170px] sm:mt-[120px] mt-[100px]">
          <div
            id="hero-mouse-move-anim"
            ref={parallaxContainerRef}
            className="absolute lg:left-[100px] sm:left-[60px] left-[20px] lg:top-[150px] top-[100px]"
          >
            <span
              className="chi-top-text items-end text-white font-semibold text-base sm:text-xl"
              data-depth="0.25"
            >
              EXCELLENT
            </span>
          </div>
          <div
            id="hero-mouse-move-anim"
            ref={parallaxContainerRef1}
            className="absolute text-center top-[40px] left-0 right-0 m-0 flex justify-center "
          >
            <span
              className="chi-top-text1 items-end text-white font-semibold text-base sm:text-xl md:top-[-50px]"
              data-depth="0.15"
            >
              LOVE IT
            </span>
          </div>

          <div
            id="hero-mouse-move-anim"
            ref={parallaxContainerRef2}
            className="absolute lg:right-[100px] sm:right-[60px] right-[20px] top-[100px]"
          >
            <span
              className="chi-top-text items-end text-white font-semibold text-base sm:text-xl"
              data-depth="0.25"
            >
              RECOMMENDED!
            </span>
          </div>

          <div
            id="hero-mouse-move-anim"
            ref={parallaxContainerRef3}
            className="  absolute lg:left-[100px] sm:left-[60px] left-[20px]  bottom-[20px]"
          >
            <span
              className="hi-main-text1 items-end text-white font-semibold text-base sm:text-xl"
              data-depth="0.3"
            >
              REALLY <br />
              <span className="ml-[25px] text-[#35FC09] text-2xl sm:text-4xl">
                {" "}
                COOL
              </span>
            </span>
          </div>
          <img
            src={main_image}
            alt="image"
            className="hairsindia-main-image w-[75%] md:w-[100%] md:max-w-[550px] xl:max-w-[600px] relative z-1"
          />
          <div
            id="hero-mouse-move-anim"
            ref={parallaxContainerRef4}
            className="absolute lg:right-[100px] sm:right-[60px] right-[20px] lg:bottom-[100px] bottom-[40px] text-white font-semibold text-base sm:text-xl"
          >
            <span className="hi-main-text" data-depth="0.25">
              TOP NOTCH
            </span>
          </div>
        </div>
      </div>

      <div className="hairsindia-review-menu relative flex justify-center flex-wrap bg-[#6BB40E] py-[25px] px-[10px] sm:px-[60px] 2xl:px-[100px] gap-[10px] lg:gap-[50px]">
        {reviews?.map((data) => (
          <span onClick={() => scrollToAnchor(data.anchor)}>{data?.title}</span>
        ))}
      </div>

      <div id="video-reviews" className="hairsindia-video-page">
        <div className="hairsindia-review-video-title relative flex justify-center items-center sm:py-[20px] bg-[#FFC303] w-full ">
          <span
            className="relative wow bounceInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            Video
          </span>
        </div>
        <div className="hairsindia-review-section">
          <img className="hairsindia-gif-image" src={logo_image} alt="image" />
          <img src={review_sculp} className="hairsindia-review-main-sculp" />
          <div className="hairsindia-video-review flex justify-center items-center relative">
            <div className="hairsindia-video-review-section flex justify-center flex-col relative">
              <div className="hairsindia-review-big-video">
                <video
                  ref={videoRef}
                  controls
                  src={selectedVideo}
                  className="video-element"
                  autoPlay
                >
                  Your browser does not support the video tag.
                </video>
                <div
                  className="play-icon-container"
                  onClick={() =>
                    handleVideoClick(selectedVideo, activeVideoIndex)
                  }
                >
                  {isPlaying ? pauseIcon : playIcon}
                </div>
              </div>
              <Swiper
                ref={swiperVideoRef}
                edgeSwipeDetection
                style={{ width: "100%" }}
                modules={[Navigation, A11y]}
                pagination={false}
                scrollbar={false}
                spaceBetween={8}
                slidesPerView="auto"
                noSwiping={navigationVideoButtonHide.isEnd}
                onBeforeInit={(item) => {
                  if (item) {
                    setNavigationVIdeoButtonHide({
                      isEnd: item?.isEnd,
                      isBeginning: item?.isBeginning,
                    });
                  }
                }}
                onSlideChange={(item) => {
                  setNavigationVIdeoButtonHide({
                    isEnd: item?.isEnd,
                    isBeginning: item?.isBeginning,
                  });
                }}
                roundLengths="true"
              >
                <span className="flex flex-row gap-[20px]">
                  {videos.map((video, index) => (
                    <SwiperSlide
                      key={index}
                      className={`small-video-container ${
                        activeVideoIndex === index ? "active" : ""
                      }`}
                    >
                      <video
                        src={video.src}
                        alt={`video thumbnail ${index}`}
                        className="hairsindia-review-small-video"
                        muted
                        onClick={() => handleVideoPass(video.src, index)}
                      />
                      <div className="s-play-icon-container">
                        {activeVideoIndex === index && isPlaying
                          ? pauseIcon
                          : playIcon}
                      </div>
                    </SwiperSlide>
                  ))}
                </span>
              </Swiper>
              <div className="hairsindia-video-swiper-btns relative flex justify-between items-center w-full">
                {!navigationVideoButtonHide.isBeginning && (
                  <button
                    className="foundation-card-prev"
                    onClick={handleVideoPrevButtonClick}
                  >
                    {arrowIcon}
                  </button>
                )}
                {!navigationVideoButtonHide.isEnd && (
                  <button
                    className="foundation-card-next"
                    onClick={handleVideoNextButtonClick}
                  >
                    {arrowIcon}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="photo-reviews" className="hairsindia-photo-page">
        <div className="hairsindia-review-photo-title relative flex justify-center items-center sm:py-[20px] bg-[#FFC303] w-full ">
          <span
            className="relative wow bounceInDown"
            data-wow-duration="1.2s"
            data-wow-delay="0.5s"
          >
            Photo
          </span>
        </div>
        <div className="hairsindia-review-section justify-center items-center">
          <img className="hairsindia-gif-image" src={logo_image} alt="image" />
          <img src={review_sculp} className="hairsindia-review-main-sculp" />
          <div className="photo-review-section flex justify-center items-center">
            <img src={selectedImage} alt="selected_image" />
          </div>

          <Swiper
            ref={swiperPhotoRef}
            edgeSwipeDetection
            style={{ width: "100%" }}
            modules={[Navigation, A11y]}
            pagination={false}
            scrollbar={false}
            spaceBetween={8}
            slidesPerView="auto"
            noSwiping={navigationButtonHide.isEnd}
            onBeforeInit={(item) => {
              if (item) {
                setNavigationButtonHide({
                  isEnd: item?.isEnd,
                  isBeginning: item?.isBeginning,
                });
              }
            }}
            onSlideChange={(item) => {
              setNavigationButtonHide({
                isEnd: item?.isEnd,
                isBeginning: item?.isBeginning,
              });
            }}
            roundLengths="true"
          >
            {photos?.map((data, index) => (
              <SwiperSlide
                className={`hairsindia-swiper-image cursor-pointer ${
                  activePhotoIndex === index ? "active" : ""
                }`}
                key={index}
              >
                <img
                  src={data.image}
                  alt={`photo ${index}`}
                  onClick={() => handleImageClick(data.image, index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="hairsindia-photo-swiper-btns relative flex justify-between items-center sm:w-[600px] w-full">
            {!navigationButtonHide.isBeginning && (
              <button
                className="foundation-card-prev"
                onClick={handlePhotoPrevButtonClick}
              >
                {arrowIcon}
              </button>
            )}
            {!navigationButtonHide.isEnd && (
              <button
                className="foundation-card-next"
                onClick={handlePhotoNextButtonClick}
              >
                {arrowIcon}
              </button>
            )}
          </div>
        </div>
      </div>
      <div id="chat-reviews" className="hairsindia-review-chat-page">
        <div className="hairsindia-review-photo-title relative flex justify-center items-center sm:py-[20px] bg-[#FFC303] w-full ">
          <span
            className="relative wow bounceInRight"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            Chat
          </span>
        </div>
        <div className="hairsindia-review-section justify-center items-center">
          <img className="hairsindia-gif-image" src={logo_image} alt="image" />
          <img src={review_sculp} className="hairsindia-review-main-sculp" />
          <div
            className="photo-review-section flex justify-center items-center"
            // style={{ '--background-image': `url(${selectedImage})` }}
          >
            <img src={selectedChat} alt="selected_image" />
          </div>

          <Swiper
            ref={swiperChatRef}
            edgeSwipeDetection
            style={{ width: "100%" }}
            modules={[Navigation, A11y]}
            pagination={false}
            scrollbar={false}
            spaceBetween={8}
            slidesPerView="auto"
            noSwiping={navigationChatButtonHide.isEnd}
            onBeforeInit={(item) => {
              if (item) {
                setNavigationChatButtonHide({
                  isEnd: item?.isEnd,
                  isBeginning: item?.isBeginning,
                });
              }
            }}
            onSlideChange={(item) => {
              setNavigationChatButtonHide({
                isEnd: item?.isEnd,
                isBeginning: item?.isBeginning,
              });
            }}
            roundLengths="true"
          >
            {Chat?.map((data, index) => (
              <SwiperSlide
                className={`hairsindia-swiper-image cursor-pointer ${
                  activeChatIndex === index ? "active" : ""
                }`}
                key={index}
              >
                <img
                  src={data.image}
                  alt={`photo ${index}`}
                  onClick={() => handleChatClick(data.image, index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="hairsindia-photo-swiper-btns relative flex justify-between items-center sm:w-[600px] w-full">
            {!navigationChatButtonHide.isBeginning && (
              <button
                className="foundation-card-prev"
                onClick={handleChatPrevButtonClick}
              >
                {arrowIcon}
              </button>
            )}
            {!navigationChatButtonHide.isEnd && (
              <button
                className="foundation-card-next"
                onClick={handleChatNextButtonClick}
              >
                {arrowIcon}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewsPage;
